import React, { useState } from "react";

const LoadingContext = React.createContext({
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {},
});

export const LoadingContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoadingHandler = () => {
    setIsLoading(true);
  };

  const stopLoadingHandler = () => {
    setIsLoading(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoading: isLoading,
        startLoading: startLoadingHandler,
        stopLoading: stopLoadingHandler,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
