import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const RefundPolicy = () => {
  useEffect(() => {
    document.title = "Refund Policy";
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <>
        <section className="wrapper bg-soft-primary">
          <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
            <div className="row">
              <div className="col-md-10 col-xl-8 mx-auto">
                <div className="post-header">
                  <h1 className="display-1 mb-5">Refund Policy</h1>
                  {/* /.post-meta */}
                </div>
                {/* /.post-header */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="blog single mt-n17">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <>
                        <h1>Refund Policy</h1>
                        <p>
                          <strong>Effective Date:</strong> 30/09/2018
                        </p>
                        <p>
                          At FORMAX IT SOLUTIONS PVT LTD, we strive to provide
                          the highest quality services to our clients. However,
                          we understand that there may be situations where a
                          refund is necessary. This Refund Policy outlines the
                          terms and conditions for refunds related to our
                          services.
                        </p>
                        <h2>1. Eligibility for Refunds</h2>
                        <p>
                          Refunds may be considered under the following
                          circumstances:
                        </p>
                        <ul>
                          <li>
                            <strong>Service Non-Delivery:</strong> If the
                            service is not delivered within the agreed-upon
                            timeframe.
                          </li>
                          <li>
                            <strong>Major Defects:</strong> If there are major
                            defects or issues with the delivered service that
                            cannot be resolved within a reasonable period.
                          </li>
                          <li>
                            <strong>Duplicate Payment:</strong> If a payment is
                            made more than once for the same service.
                          </li>
                        </ul>
                        <h2>2. Non-Refundable Circumstances</h2>
                        <p>
                          Refunds will not be provided under the following
                          conditions:
                        </p>
                        <ul>
                          <li>
                            <strong>Change of Mind:</strong> If the client
                            changes their mind after the service has been
                            delivered or partially delivered.
                          </li>
                          <li>
                            <strong>Minor Defects:</strong> If the issues or
                            defects are minor and can be fixed within a
                            reasonable timeframe.
                          </li>
                          <li>
                            <strong>Third-Party Products/Services:</strong>{" "}
                            Refunds for third-party products or services
                            purchased through or recommended by FORMAX IT
                            SOLUTIONS PVT LTD are subject to the terms of the
                            third-party provider.
                          </li>
                        </ul>
                        <h2>3. Refund Request Process</h2>
                        <p>
                          To request a refund, clients must follow these steps:
                        </p>
                        <ol>
                          <li>
                            <strong>Contact Us:</strong> Reach out to our
                            customer support team at [contact email/phone
                            number] with the details of your refund request,
                            including the service provided, date of purchase,
                            and reason for the refund.
                          </li>
                          <li>
                            <strong>Review:</strong> Our team will review the
                            request and may ask for additional information or
                            clarification.
                          </li>
                          <li>
                            <strong>Decision:</strong> We will inform the client
                            of our decision within [X] business days. If
                            approved, the refund will be processed within [Y]
                            business days.
                          </li>
                        </ol>
                        <h2>4. Refund Method</h2>
                        <p>
                          Approved refunds will be processed using the same
                          payment method used for the original purchase unless
                          otherwise agreed upon. The client is responsible for
                          any fees associated with the refund process.
                        </p>
                        <h2>5. Changes to the Refund Policy</h2>
                        <p>
                          FORMAX IT SOLUTIONS PVT LTD reserves the right to
                          modify this Refund Policy at any time. Any changes
                          will be posted on our website with the effective date.
                        </p>
                        <h2>6. Contact Information</h2>
                        <p>
                          If you have any questions about our Refund Policy,
                          please contact us at:
                        </p>
                        <div className="contact-info">
                          <p>
                            <strong>FORMAX IT SOLUTIONS PVT LTD</strong>
                            <br />
                            Ground Floor, 16, Mochpole, Near Junior School,
                            Nabapally, Barasat, Kolkata - 700126, West Bengal
                            <br />
                            info@formaxit.com
                          </p>
                        </div>
                        <p>
                          <strong>Note:</strong> This policy is subject to our
                          Terms of Service and any other agreements between
                          FORMAX IT SOLUTIONS PVT LTD and the client.
                        </p>
                        <p>
                          Please review this policy carefully and ensure that
                          you understand our refund process before purchasing
                          any services.
                        </p>
                      </>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.blog */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
      </>
      <Footer />
    </React.Fragment>
  );
};

export default RefundPolicy;
