import React from "react";

const GetInTouch = () => {
  return (
    <React.Fragment>
      <section className="wrapper bg-light">
        <div className="container py-1 pb-md-12 position-relative">
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-md-8 col-lg-6 position-relative">
              <div
                className="shape bg-dot primary rellax w-17 h-21"
                data-rellax-speed={1}
                style={{ top: "-2rem", left: "-1.9rem" }}
              />
              <div
                className="shape rounded bg-soft-primary rellax d-md-block"
                data-rellax-speed={0}
                style={{
                  bottom: "-1.8rem",
                  right: "-1.5rem",
                  width: "85%",
                  height: "90%",
                }}
              />
              <figure className="rounded">
                <img src="/images/contact-us-2.jpg" alt="" />
              </figure>
            </div>
            {/*/column */}
            <div className="col-lg-5 col-xl-4 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                Get In Touch
              </h2>
              <h2 className="display-4 mb-8">
                Convinced yet? Let's make something great together.
              </h2>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-6 mt-n1">
                    {" "}
                    <i className="uil uil-location-pin-alt" />{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Address</h5>
                  <address>
                    Formax It Solutions Pvt Ltd, <br /> Ground Floor, 16,
                    Mochpole, Near Junior School, Nabapally, Barasat, Kolkata -
                    700126, West Bengal, India
                  </address>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-6 mt-n1">
                    {" "}
                    <i className="uil uil-phone-volume" />{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Phone</h5>
                  <p>+91 9836-1145-20</p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div className="icon text-primary fs-28 me-6 mt-n1">
                    {" "}
                    <i className="uil uil-envelope" />{" "}
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">E-mail</h5>
                  <p className="mb-0">
                    <a href="mailto: info@formaxit.com" className="link-body">
                      info@formaxit.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
    </React.Fragment>
  );
};

export default GetInTouch;
