import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  useEffect(() => {
    document.title =
      "Contact Us - Best Software Development Company in Kolkata, India | Formax It Solutions";
  }, []);

  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    if (fname == "") {
      Swal.fire("Opps!", "Please Enter First Name", "error");
      return;
    }

    if (lname == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (email == "") {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (mobile == "") {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (subject == "") {
      Swal.fire("Opps!", "Please Enter Subject", "error");
      return;
    }

    if (message == "") {
      Swal.fire("Opps!", "Please Enter Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        fname + " " + lname,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="content-wrapper">
        <NavBar />

        <>
          <section
            className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white"
            data-image-src="/assets/img/photos/bg3.jpg"
          >
            <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <h1 className="display-1 mb-3 text-white">Get in Touch</h1>
                  <nav className="d-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb text-white">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact
                      </li>
                    </ol>
                  </nav>
                  {/* /nav */}
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /section */}
          <section className="wrapper bg-light angled upper-end">
            <div className="container pb-11">
              <div className="row mb-14 mb-md-16">
                <div className="col-xl-10 mx-auto mt-n19">
                  <div className="card">
                    <div className="row gx-0">
                      <div className="col-lg-6 align-self-stretch">
                        <div className="map map-full rounded-top rounded-lg-start">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7359.280163665329!2d88.4338849925285!3d22.741615317486453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d22.7387387!2d88.4415106!4m5!1s0x39f899f2d9615027%3A0x8b7693dab1225dc8!2sformax%20it%20solutions!3m2!1d22.7442159!2d88.4390018!5e0!3m2!1sen!2sin!4v1719503591630!5m2!1sen!2sin"
                            style={{ width: "100%", height: "100%", border: 0 }}
                            allowFullScreen=""
                          />
                        </div>
                        {/* /.map */}
                      </div>
                      {/*/column */}
                      <div className="col-lg-6">
                        <div className="p-10 p-md-11 p-lg-14">
                          <div className="d-flex flex-row">
                            <div>
                              <div className="icon text-primary fs-28 me-4 mt-n1">
                                {" "}
                                <i className="uil uil-location-pin-alt" />{" "}
                              </div>
                            </div>
                            <div className="align-self-start justify-content-start">
                              <h5 className="mb-1">Address</h5>
                              <address>
                                Formax It Solutions Pvt Ltd, <br />
                                Ground Floor, 16, Mochpole, Near Junior School,
                                Nabapally, Barasat, Kolkata - 700126, West
                                Bengal
                              </address>
                            </div>
                          </div>
                          {/*/div */}
                          <div className="d-flex flex-row">
                            <div>
                              <div className="icon text-primary fs-28 me-4 mt-n1">
                                {" "}
                                <i className="uil uil-phone-volume" />{" "}
                              </div>
                            </div>
                            <div>
                              <h5 className="mb-1">Phone</h5>
                              <p>+91 9836-1145-20</p>
                            </div>
                          </div>
                          {/*/div */}
                          <div className="d-flex flex-row">
                            <div>
                              <div className="icon text-primary fs-28 me-4 mt-n1">
                                {" "}
                                <i className="uil uil-envelope" />{" "}
                              </div>
                            </div>
                            <div>
                              <h5 className="mb-1">E-mail</h5>
                              <p className="mb-0">
                                <a
                                  href="mailto:info@formaxit.com"
                                  className="link-body"
                                >
                                  info@formaxit.com
                                </a>
                              </p>
                              <p className="mb-0">
                                <a
                                  href="mailto:partnership@formaxpay.com"
                                  className="link-body"
                                >
                                  partnership@formaxpay.com
                                </a>
                              </p>
                            </div>
                          </div>
                          {/*/div */}
                        </div>
                        {/*/div */}
                      </div>
                      {/*/column */}
                    </div>
                    {/*/.row */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h2 className="display-4 mb-3 text-center">Drop Us a Line</h2>
                  <p className="lead text-center mb-10">
                    Reach out to us from our contact form and we will get back
                    to you shortly.
                  </p>
                  <form className="contact-form needs-validation" noValidate="">
                    <div className="messages" />
                    <div className="row gx-4">
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          <input
                            id="form_name"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            required=""
                            ref={fnameRef}
                          />
                          <label htmlFor="form_name">First Name *</label>
                        </div>
                      </div>
                      {/* /column */}
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          <input
                            id="form_lastname"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            required=""
                            ref={lnameRef}
                          />
                          <label htmlFor="form_lastname">Last Name *</label>
                        </div>
                      </div>
                      {/* /column */}
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          <input
                            id="form_name"
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            required=""
                            ref={emailRef}
                          />
                          <label htmlFor="form_name">Email *</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          <input
                            id="form_name"
                            type="text"
                            className="form-control"
                            placeholder="Mobile"
                            required=""
                            ref={mobileRef}
                          />
                          <label htmlFor="form_name">Mobile *</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          <input
                            id="form_email"
                            type="email"
                            name="email"
                            className="form-control"
                            required=""
                            ref={subjectRef}
                          />
                          <label htmlFor="form_email">Subject *</label>
                        </div>
                      </div>
                      {/* /column */}
                      <div className="col-12">
                        <div className="form-floating mb-4">
                          <textarea
                            id="form_message"
                            name="message"
                            className="form-control"
                            placeholder="Your message"
                            style={{ height: 150 }}
                            required=""
                            defaultValue={""}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                          />
                          <label htmlFor="form_message">Message *</label>
                        </div>
                      </div>
                      {/* /column */}
                      <div className="col-12 text-center">
                        <input
                          type="button"
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          defaultValue="Send message"
                          onClick={submitHandler}
                        />
                        <p className="text-muted">
                          <strong>*</strong> These fields are required.
                        </p>
                      </div>
                      {/* /column */}
                    </div>
                    {/* /.row */}
                  </form>
                  {/* /form */}
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /section */}
          <section
            className="wrapper image-wrapper bg-auto no-overlay bg-image text-center bg-map"
            data-image-src="./assets/img/map.png"
          >
            <div className="container pt-0 pb-14 pt-md-16 pb-md-18">
              <div className="row">
                <div className="col-lg-9 col-xxl-8 mx-auto">
                  <h3 className="display-4 mb-8 px-xl-12">
                    We are trusted by over 350+ clients. Join them now and grow
                    your business.
                  </h3>
                </div>
                {/* /.row */}
              </div>
              {/* /column */}
              <div className="row">
                <div className="col-md-10 col-lg-9 col-xl-7 mx-auto">
                  <div className="row align-items-center counter-wrapper gy-4 gy-md-0">
                    <div className="col-md-4 text-center">
                      <h3 className="counter counter-lg text-primary">350+</h3>
                      <p>Completed Projects</p>
                    </div>
                    {/*/column */}
                    <div className="col-md-4 text-center">
                      <h3 className="counter counter-lg text-primary">200+</h3>
                      <p>Satisfied Customers</p>
                    </div>
                    {/*/column */}
                    <div className="col-md-4 text-center">
                      <h3 className="counter counter-lg text-primary">7+</h3>
                      <p>Years of Experience</p>
                    </div>
                    {/*/column */}
                  </div>
                  {/*/.row */}
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /section */}
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
