class HttpError {
  getError = (error) => {
    let errMsg = "";
    if (error.response) {
      errMsg =
        error.response.data.message ||
        "Something went wrong, please try again later";
    } else if (error.request) {
      errMsg = error.message || "Something went wrong, please try again later";
    } else {
      errMsg = error.message || "Something went wrong, please try again later";
    }
    return errMsg;
  };
}

export default new HttpError();
