import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const AboutUs = () => {
  useEffect(() => {
    document.title =
      "About Us - Best Software Development Company in Kolkata, India | Formax It Solutions";
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <section class=" overflow-hidden">
        {/* section-frame */}
        <div class="wrapper bg-gray">
          <div class="container py-13 py-md-17 text-center">
            <div class="row">
              <div class="col-lg-10 col-xxl-8 mx-auto">
                <h1 class="display-1 mb-1">About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light angled upper-end lower-end">
        <div className="container py-14 py-md-5">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-1 md-17 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="">
                <div className="item">
                  <figure className="rounded">
                    <img src="./images/about_us_2.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            {/*/column */}
            <div className="col-lg-6">
              <img
                src="./assets/img/icons/lineal/megaphone.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-3">About Formax It Solutions</h2>
              <p style={{ textAlign: "justify" }}>
                Located in the vibrant city of Kolkata, Formax IT Solutions has
                been a beacon of innovation and excellence in the IT industry
                since 2018. With a team of seven dedicated professionals, we
                bring over seven years of collective experience to the table,
                ensuring that we deliver top-notch solutions tailored to your
                unique business needs.
              </p>
              <p className="mb-6" style={{ textAlign: "justify" }}>
                Formax IT Solutions stands at the forefront of the digital
                revolution, offering comprehensive IT solutions that empower
                businesses to thrive in a competitive world. Our expertise in
                software development, app development, and website design
                ensures that we deliver cutting-edge solutions tailored to meet
                the unique needs of our clients.
              </p>
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>

      {/* /section */}
      <section className="wrapper">
        <div className="container py-14 py-md-16">
          {/*/.row */}
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-7 order-lg-2">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/i11.png"
                  srcSet="./assets/img/illustrations/i11@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>
            {/*/column */}
            <div className="col-lg-5">
              <h2 className="fs-15 text-uppercase text-line text-primary mb-3">
                Why Choose Us?
              </h2>
              <h3 className="display-5 mb-7">
                A few reasons why our valued customers choose us.
              </h3>
              <div
                className="accordion accordion-wrapper"
                id="accordionExample"
              >
                <div className="card plain accordion-item">
                  <div className="card-header" id="headingOne">
                    <button
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Experienced Professionals
                    </button>
                  </div>
                  {/*/.card-header */}
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Our team comprises highly skilled and experienced IT
                        professionals who bring a wealth of knowledge and
                        expertise to every project. We are dedicated to
                        delivering solutions that are innovative, reliable, and
                        effective.
                      </p>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.accordion-collapse */}
                </div>
                {/*/.accordion-item */}
                <div className="card plain accordion-item">
                  <div className="card-header" id="headingTwo">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Customer-Centric Approach
                    </button>
                  </div>
                  {/*/.card-header */}
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        We place our clients at the heart of everything we do.
                        We take the time to understand your business goals and
                        challenges, providing personalized solutions that align
                        with your vision and objectives.
                      </p>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.accordion-collapse */}
                </div>
                {/*/.accordion-item */}
                <div className="card plain accordion-item">
                  <div className="card-header" id="headingThree">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Quality Assurance
                    </button>
                  </div>
                  {/*/.card-header */}
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Quality is our top priority. We adhere to strict quality
                        assurance processes to ensure that every solution we
                        deliver meets the highest standards of performance,
                        security, and reliability.
                      </p>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.accordion-collapse */}
                </div>
                {/*/.accordion-item */}
                <div className="card plain accordion-item">
                  <div className="card-header" id="headingFour">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Timely Delivery
                    </button>
                  </div>
                  {/*/.card-header */}
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        We respect your time and are committed to delivering
                        projects within the agreed timelines. Our efficient
                        project management practices ensure that we meet
                        deadlines without compromising on quality.
                      </p>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.accordion-collapse */}
                </div>
                {/*/.accordion-item */}
                <div className="card plain accordion-item">
                  <div className="card-header" id="headingFive">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Ongoing Support
                    </button>
                  </div>
                  {/*/.card-header */}
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p>
                        Our commitment to your success extends beyond project
                        completion. We offer ongoing support and maintenance
                        services to ensure that your IT solutions continue to
                        perform optimally and adapt to your evolving needs.
                      </p>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.accordion-collapse */}
                </div>
                {/*/.accordion-item */}
              </div>
              {/*/.accordion */}
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>
      <Testimonials />
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
