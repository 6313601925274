import React from "react";

const Testimonials = () => {
  return (
    <React.Fragment>
      <>
        <section className="wrapper bg-soft-primary">
          <div className="container py-14 pt-md-17 pb-md-20">
            <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0">
              <div className="col-lg-4 text-center text-lg-start">
                <h3 className="display-4 mb-3 pe-xl-15">
                  We are proud of our works
                </h3>
                <p className="lead fs-lg mb-0 pe-xxl-10">
                  We bring solutions to make life easier for our customers.
                </p>
              </div>
              {/* /column */}
              <div className="col-lg-8 mt-lg-2">
                <div className="row align-items-center counter-wrapper gy-6 text-center">
                  <div className="col-md-4">
                    <img
                      src="./assets/img/icons/lineal/check.svg"
                      className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                      alt=""
                    />
                    <h3 className="counter">350+</h3>
                    <p>Completed Projects</p>
                  </div>
                  {/*/column */}
                  <div className="col-md-4">
                    <img
                      src="./assets/img/icons/lineal/user.svg"
                      className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                      alt=""
                    />
                    <h3 className="counter">200+</h3>
                    <p>Happy Customers</p>
                  </div>
                  {/*/column */}
                  <div className="col-md-4">
                    <img
                      src="./assets/img/icons/lineal/briefcase-2.svg"
                      className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                      alt=""
                    />
                    <h3 className="counter">7+</h3>
                    <p>Years of Experience</p>
                  </div>
                  {/*/column */}
                </div>
                {/*/.row */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container py-14 py-md-16 pb-md-5">
            <div className="grid mb-14 mb-md-18 mt-3">
              <div className="row isotope gy-6 mt-n19 mt-md-n22">
                <div className="item col-md-6 col-xl-3">
                  <div className="card shadow-lg card-border-bottom border-soft-primary">
                    <div className="card-body">
                      <span className="ratings five mb-3" />
                      <blockquote className="icon mb-0">
                        <p style={{ fontSize: "13px" }}>
                          “Working with Formax IT was a game-changer for our
                          business. Their innovative solutions and attention to
                          detail helped us create a website that truly stands
                          out. The team's ability to understand our vision and
                          turn it into reality was impressive. We’ve seen a
                          significant increase in user engagement and overall
                          traffic since the new site launched.”
                        </p>
                        <div className="blockquote-details">
                          <div className="info ps-0">
                            <h5 className="mb-1">Sarah M</h5>
                            <p className="mb-0">CEO of Global Ventures</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/*/column */}
                <div className="item col-md-6 col-xl-3">
                  <div className="card shadow-lg card-border-bottom border-soft-primary">
                    <div className="card-body">
                      <span className="ratings five mb-3" />
                      <blockquote className="icon mb-0">
                        <p style={{ fontSize: "13px" }}>
                          “The team at Formax provided exceptional customer
                          service from start to finish. They were always
                          available to answer our questions and made the entire
                          process seamless. Their expertise in web development
                          is unmatched, and we couldn't be happier with the end
                          result.”
                        </p>
                        <div className="blockquote-details">
                          <div className="info ps-0">
                            <h5 className="mb-1">Kartik S</h5>
                            <p className="mb-0">Director of IPAY</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/*/column */}
                <div className="item col-md-6 col-xl-3">
                  <div className="card shadow-lg card-border-bottom border-soft-primary">
                    <div className="card-body">
                      <span className="ratings five mb-3" />
                      <blockquote className="icon mb-0">
                        <p style={{ fontSize: "13px" }}>
                          “Formax exceeded our expectations in every way. They
                          are reliable, professional, and extremely
                          knowledgeable about the latest web development trends.
                          The project was delivered on time and within budget,
                          and the final product was beyond what we had
                          envisioned.”
                        </p>
                        <div className="blockquote-details">
                          <div className="info ps-0">
                            <h5 className="mb-1">Anowar H</h5>
                            <p className="mb-0">CEO of Nexxture Trading</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/*/column */}
                <div className="item col-md-6 col-xl-3">
                  <div className="card shadow-lg card-border-bottom border-soft-primary">
                    <div className="card-body">
                      <span className="ratings five mb-3" />
                      <blockquote className="icon mb-0">
                        <p style={{ fontSize: "13px" }}>
                          “Collaborating with Formax IT was a seamless
                          experience. They took the time to understand our brand
                          and objectives, and the communication throughout the
                          project was excellent. The final website is not only
                          visually appealing but also highly functional. We look
                          forward to working with them again in the future.”
                        </p>
                        <div className="blockquote-details">
                          <div className="info ps-0">
                            <h5 className="mb-1">Naren N</h5>
                            <p className="mb-0">Founder of Nagar Pay</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/*/column */}
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container */}
        </section>
      </>
    </React.Fragment>
  );
};

export default Testimonials;
