import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Terms And Conditions";
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <>
        <section className="wrapper bg-soft-primary">
          <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
            <div className="row">
              <div className="col-md-10 col-xl-8 mx-auto">
                <div className="post-header">
                  <h1 className="display-1 mb-5">Terms And Conditions</h1>
                  {/* /.post-meta */}
                </div>
                {/* /.post-header */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="blog single mt-n17">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <>
                        <h1>Terms and Conditions</h1>
                        <p>
                          <strong>Effective Date:</strong> [Date]
                        </p>
                        <p>
                          Welcome to FORMAX IT SOLUTIONS PVT LTD ("we", "us", or
                          "our"). These Terms and Conditions govern your use of
                          our website and services. By accessing or using our
                          website or services, you agree to be bound by these
                          Terms and Conditions. If you do not agree with these
                          Terms and Conditions, you must not use our website or
                          services.
                        </p>
                        <h2>1. Definitions</h2>
                        <ul>
                          <li>
                            <strong>Services:</strong> Refers to the services
                            provided by FORMAX IT SOLUTIONS PVT LTD, including
                            Software Development, Mobile App Development,
                            Website Design, Website Development, Graphics
                            Design, and SEO Services.
                          </li>
                          <li>
                            <strong>User:</strong> Refers to any individual or
                            entity accessing or using our website or services.
                          </li>
                          <li>
                            <strong>Content:</strong> Refers to all text,
                            graphics, images, and other material available on
                            our website.
                          </li>
                        </ul>
                        <h2>2. Use of Services</h2>
                        <ul>
                          <li>
                            <strong>Eligibility:</strong> You must be at least
                            18 years old to use our services.
                          </li>
                          <li>
                            <strong>Compliance:</strong> You agree to use our
                            services in compliance with all applicable laws and
                            regulations.
                          </li>
                          <li>
                            <strong>Account:</strong> If you create an account
                            with us, you are responsible for maintaining the
                            confidentiality of your account information and for
                            all activities that occur under your account.
                          </li>
                        </ul>
                        <h2>3. Intellectual Property</h2>
                        <ul>
                          <li>
                            <strong>Ownership:</strong> All content, trademarks,
                            logos, and intellectual property on our website are
                            the property of FORMAX IT SOLUTIONS PVT LTD or its
                            licensors.
                          </li>
                          <li>
                            <strong>Restrictions:</strong> You may not
                            reproduce, distribute, modify, or create derivative
                            works of any content on our website without our
                            prior written permission.
                          </li>
                        </ul>
                        <h2>4. Prohibited Activities</h2>
                        <p>You agree not to:</p>
                        <ul>
                          <li>Use our services for any unlawful purpose.</li>
                          <li>
                            Attempt to gain unauthorized access to our systems
                            or networks.
                          </li>
                          <li>
                            Engage in any activity that could damage, disable,
                            or impair our services.
                          </li>
                        </ul>
                        <h2>5. Fees and Payments</h2>
                        <ul>
                          <li>
                            <strong>Payment Terms:</strong> Payments for our
                            services must be made according to the terms
                            specified in the relevant agreement or invoice.
                          </li>
                          <li>
                            <strong>Taxes:</strong> You are responsible for all
                            applicable taxes related to your use of our
                            services.
                          </li>
                        </ul>
                        <h2>6. Termination</h2>
                        <p>
                          We may terminate or suspend your access to our
                          services at any time, without prior notice or
                          liability, for any reason, including if you breach
                          these Terms and Conditions.
                        </p>
                        <h2>7. Disclaimers</h2>
                        <ul>
                          <li>
                            <strong>No Warranty:</strong> Our services are
                            provided "as is" and "as available" without any
                            warranties, express or implied.
                          </li>
                          <li>
                            <strong>Limitation of Liability:</strong> In no
                            event shall FORMAX IT SOLUTIONS PVT LTD be liable
                            for any indirect, incidental, special, or
                            consequential damages arising out of or in
                            connection with your use of our services.
                          </li>
                        </ul>
                        <h2>8. Indemnification</h2>
                        <p>
                          You agree to indemnify and hold harmless FORMAX IT
                          SOLUTIONS PVT LTD and its employees, agents, and
                          affiliates from any claims, damages, or expenses
                          arising out of your use of our services or your breach
                          of these Terms and Conditions.
                        </p>
                        <h2>9. Governing Law</h2>
                        <p>
                          These Terms and Conditions are governed by and
                          construed in accordance with the laws of India. Any
                          disputes arising under or in connection with these
                          Terms and Conditions shall be subject to the exclusive
                          jurisdiction of the courts located in Kolkata, India.
                        </p>
                        <h2>10. Changes to Terms and Conditions</h2>
                        <p>
                          We may update these Terms and Conditions from time to
                          time. We will notify you of any changes by posting the
                          new Terms and Conditions on our website. You are
                          advised to review these Terms and Conditions
                          periodically for any changes.
                        </p>
                        <h2>11. Contact Us</h2>
                        <p>
                          If you have any questions about these Terms and
                          Conditions, please contact us at:
                        </p>
                        <div class="contact-info">
                          <p>
                            <strong>FORMAX IT SOLUTIONS PVT LTD</strong>
                            <br />
                            Ground Floor, 16, Mochpole, Near Junior School,
                            Nabapally, Barasat, Kolkata - 700126, West Bengal
                            <br />
                            info@formaxit.com
                          </p>
                        </div>

                        <p>
                          Please read these Terms and Conditions carefully and
                          ensure that you understand them before using our
                          services.
                        </p>
                      </>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.blog */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
      </>
      <Footer />
    </React.Fragment>
  );
};

export default TermsAndConditions;
