import React from "react";

const StartHero = () => {
  return (
    <React.Fragment>
      <>
        <section className="wrapper bg-gradient-primary">
          <div className="container pt-10 pt-md-14 pb-8 text-center">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 text-center text-lg-start">
                <h1
                  className="display-1 mb-5 mx-md-n5 mx-lg-0"
                  //  style={{ fontFamily: "THICCCBOI, sans-serif" }}
                >
                  Leading Mobile App and Software Development Company in Kolkata
                </h1>
                <p className="lead fs-lg mb-7">
                  We offer comprehensive IT solutions that empower businesses to
                  thrive in a competitive world.
                </p>
                <span>
                  <a
                    className="btn btn-primary rounded-pill me-2"
                    href="/contact_us"
                  >
                    Free Consultation
                  </a>
                </span>
              </div>
              {/* /column */}
              <div className="col-lg-6">
                <figure>
                  <img
                    className="w-auto"
                    src="/images/web-development-banner.svg"
                    alt="web-development-banner"
                  />
                </figure>
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
      </>
    </React.Fragment>
  );
};

export default StartHero;
