import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <>
        <section className="wrapper bg-soft-primary">
          <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
            <div className="row">
              <div className="col-md-10 col-xl-8 mx-auto">
                <div className="post-header">
                  <h1 className="display-1 mb-5">Privacy Policy</h1>
                  {/* /.post-meta */}
                </div>
                {/* /.post-header */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="blog single mt-n17">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <>
                        <h1>Privacy Policy</h1>
                        <p>
                          <strong>Effective Date:</strong> 30/09/2018
                        </p>
                        <p>
                          FORMAX IT SOLUTIONS PVT LTD ("we", "us", or "our") is
                          committed to protecting the privacy of our clients and
                          website visitors. This Privacy Policy explains how we
                          collect, use, disclose, and safeguard your information
                          when you visit our website or use our services.
                        </p>
                        <h2>1. Information We Collect</h2>
                        <p>
                          We may collect the following types of information:
                        </p>
                        <ul>
                          <li>
                            <strong>Personal Information:</strong> Name, email
                            address, phone number, postal address, and other
                            contact details.
                          </li>
                          <li>
                            <strong>Technical Information:</strong> IP address,
                            browser type, operating system, and other technical
                            details from your device.
                          </li>
                          <li>
                            <strong>Usage Information:</strong> Information
                            about how you use our website and services,
                            including the pages you visit, the time spent on
                            those pages, and other related information.
                          </li>
                        </ul>
                        <h2>2. How We Use Your Information</h2>
                        <p>
                          We use the collected information for the following
                          purposes:
                        </p>
                        <ul>
                          <li>
                            <strong>Service Delivery:</strong> To provide,
                            operate, and maintain our services.
                          </li>
                          <li>
                            <strong>Communication:</strong> To contact you with
                            updates, newsletters, marketing, or promotional
                            materials.
                          </li>
                          <li>
                            <strong>Improvement:</strong> To understand and
                            analyze how you use our website and services, and to
                            improve and enhance them.
                          </li>
                          <li>
                            <strong>Security:</strong> To detect, prevent, and
                            address technical issues and to protect against
                            fraud.
                          </li>
                        </ul>
                        <h2>3. How We Share Your Information</h2>
                        <p>
                          We do not sell, trade, or otherwise transfer your
                          Personal Information to outside parties except as
                          follows:
                        </p>
                        <ul>
                          <li>
                            <strong>Service Providers:</strong> We may share
                            your information with third-party service providers
                            who assist us in operating our website and providing
                            our services.
                          </li>
                          <li>
                            <strong>Legal Requirements:</strong> We may disclose
                            your information if required to do so by law or in
                            response to valid requests by public authorities.
                          </li>
                          <li>
                            <strong>Business Transfers:</strong> In the event of
                            a merger, acquisition, or sale of all or a portion
                            of our assets, your information may be transferred
                            as part of that transaction.
                          </li>
                        </ul>
                        <h2>4. Data Security</h2>
                        <p>
                          We implement a variety of security measures to
                          maintain the safety of your Personal Information.
                          However, no method of transmission over the Internet
                          or method of electronic storage is 100% secure, and we
                          cannot guarantee absolute security.
                        </p>
                        <h2>5. Your Rights</h2>
                        <p>
                          You have the following rights regarding your Personal
                          Information:
                        </p>
                        <ul>
                          <li>
                            <strong>Access:</strong> You can request access to
                            the information we hold about you.
                          </li>
                          <li>
                            <strong>Correction:</strong> You can request that we
                            correct any inaccuracies in your information.
                          </li>
                          <li>
                            <strong>Deletion:</strong> You can request that we
                            delete your information, subject to certain
                            exceptions.
                          </li>
                          <li>
                            <strong>Opt-Out:</strong> You can opt-out of
                            receiving marketing communications from us by
                            following the unsubscribe instructions included in
                            those communications.
                          </li>
                        </ul>
                        <h2>6. Cookies and Tracking Technologies</h2>
                        <p>
                          We use cookies and similar tracking technologies to
                          track the activity on our website and hold certain
                          information. You can instruct your browser to refuse
                          all cookies or to indicate when a cookie is being
                          sent. However, if you do not accept cookies, you may
                          not be able to use some portions of our website.
                        </p>
                        <h2>7. Third-Party Links</h2>
                        <p>
                          Our website may contain links to other sites that are
                          not operated by us. We have no control over and assume
                          no responsibility for the content, privacy policies,
                          or practices of any third-party sites or services.
                        </p>
                        <h2>8. Changes to This Privacy Policy</h2>
                        <p>
                          We may update our Privacy Policy from time to time. We
                          will notify you of any changes by posting the new
                          Privacy Policy on this page and updating the effective
                          date. You are advised to review this Privacy Policy
                          periodically for any changes.
                        </p>
                        <h2>9. Contact Us</h2>
                        <p>
                          If you have any questions about this Privacy Policy,
                          please contact us at:
                        </p>
                        <div className="contact-info">
                          <p>
                            <strong>FORMAX IT SOLUTIONS PVT LTD</strong>
                            <br />
                            Ground Floor, 16, Mochpole, Near Junior School,
                            Nabapally, Barasat, Kolkata - 700126, West Bengal
                            <br />
                            info@formaxit.com
                          </p>
                        </div>
                        <p>
                          Please review this policy carefully and ensure that
                          you understand our practices regarding your
                          information before using our services.
                        </p>
                      </>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.blog */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
      </>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
