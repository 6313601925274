import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-dark text-inverse">
        <div className="container pt-13 pt-md-15 pb-7">
          <div className="row gy-6 gy-lg-0">
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <img
                  className="mb-4"
                  src="/images/white-logo.png"
                  alt="formaxit-logo"
                  height={35}
                />
                <p className="lead mb-5">
                  Formax IT Solutions stands at the forefront of the digital
                  revolution, offering comprehensive IT solutions that empower
                  businesses to thrive in a competitive world.
                </p>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3">Services</h4>
                <ul className="list-unstyled text-reset mb-0">
                  <li>
                    <a href="#">Software Development</a>
                  </li>
                  <li>
                    <a href="#">Mobile App Development</a>
                  </li>
                  <li>
                    <a href="#">Website Design</a>
                  </li>
                  <li>
                    <a href="#">Website Development</a>
                  </li>
                  <li>
                    <a href="#">Graphics Design</a>
                  </li>
                  <li>
                    <a href="#">SEO Services</a>
                  </li>
                </ul>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3">Quick Links</h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <a href="/about_us">About Us</a>
                  </li>
                  <li>
                    <a href="/contact_us">Contact Us</a>
                  </li>
                  <li>
                    <a href="/terms_and_conditions">Terms and Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy_policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/refund_policy">Refund Policy</a>
                  </li>
                </ul>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-12 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white">Contact Us</h4>
                <p>
                  <i className="uil uil-map-marker"></i> Formax It Solutions Pvt
                  Ltd,
                  <br /> Ground Floor, 16, Mochpole, Near Junior School,
                  Nabapally, Barasat, Kolkata - 700126, West Bengal
                </p>
                <a href="mailto:info@formaxit.com">
                  <i className="uil uil-envelope"></i> info@formaxit.com
                </a>
                <br /> <i className="uil uil-phone"></i> +91 9836-1145-20
                {/* /.newsletter-wrapper */}
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
          </div>
          {/*/.row */}

          <p className="pt-10">
            CIN: U72900WB2018PTC228240 | GSTIN: 19AADCF5125F1ZT{" "}
          </p>

          <hr className="mt-7 mt-md-7 mb-7" />
          <div className="d-md-flex align-items-center justify-content-between">
            <p className="mb-2 mb-lg-0">
              Copyright © {new Date().getFullYear()} Formax It Solutions Private
              Limited
            </p>
            <nav className="nav social social-white text-md-end">
              <a
                href="https://www.facebook.com/formaxitsolutions/"
                target="_blank"
              >
                <i className="uil uil-facebook-f" />
              </a>
              <a href="https://x.com/formax_it" target="_blank">
                <i className="uil uil-twitter" />
              </a>
              <a href="https://www.instagram.com/formaxpay/" target="_blank">
                <i className="uil uil-instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/formax-pay"
                target="_blank"
              >
                <i className="uil uil-linkedin" />
              </a>
            </nav>
            {/* /.social */}
          </div>
          {/* /div */}
        </div>
        {/* /.container */}
      </footer>
    </React.Fragment>
  );
};

export default Footer;
